import React from "react"
import Img from "gatsby-image"
import RitchText from "../../../shared/components/molecules/rich-text/rich-text"
import "./section-brands-principal-info.scss"

const SectionBrandsPrincipalInfo = ({ contentInfo }) => {
  return (
    <section className="f-section-principal-info">
      <div className="f-section-principal-info__wrapper">
        <div className="f-section-principal-info__txts">
          <h1 className="f-section-principal-info__title">{contentInfo.tituloPrincipal}</h1>
          <RitchText
            className="f-section-principal-info__txts__description"
            jsonInfo={JSON.parse(contentInfo.descripcionPrincipal.raw)}
          />
        </div>
        <div className="f-section-principal-info__img">
          <div className="f-section-principal-info__img-container">
            <Img
              loading="lazy"
              itemProp="image"
              fluid={contentInfo?.imagenPrincipal[0]?.fluid}
              alt={contentInfo?.imagenPrincipal[0]?.description}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
export default SectionBrandsPrincipalInfo
