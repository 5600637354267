import React from "react"
import SectionMainBanner from "../../../shared/components/molecules/section-main-banner/section-main-banner"
import SectionBrandsPrincipalInfo from "../../molecules/section-brands-principal-info/section-brands-principal-info"
import SectionOurBrandsSlider from "../../molecules/section-our-brands-slider/section-our-brands-slider"
import SectionBrandsHitos from "../../molecules/section-brands-hitos/section-brands-hitos"
import SectionContact from "../../../shared/components/organisms/section-contact/section-contact"
import "./content-out-brands.scss"

const ContentOutBrands = ({ ourBrandsInfo }) => {
  const imagenesDelBanner = ourBrandsInfo.bannerPrincipal.imagenesDelBanner

  return (
    <>
      <SectionMainBanner
        image={imagenesDelBanner}
      />
      <SectionBrandsPrincipalInfo contentInfo={ourBrandsInfo} />
      <SectionOurBrandsSlider contentInfo={ourBrandsInfo} />
      <SectionBrandsHitos
        contentInfo={ourBrandsInfo.hitosDestacados}
        sectionTitle={ourBrandsInfo.tituloHitosDestacados}
      />
      <SectionContact />
    </>

  )

}

export default ContentOutBrands
