import React from "react"
import clsx from "clsx"
import "./section-title.scss"

const SectionSubtitle = ({ children, type = "h2", hSize = "large", barCenter }) => {

    const className = clsx([
        'f-section-title',
        hSize && `f-section-title--${hSize}`,
        barCenter && `f-section-title--bar-center`
    ])
    return React.createElement(type, { className }, children)
}

export default SectionSubtitle