import React from "react"
import "./section-our-brands-slider.scss"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import SectionTitle from "../../../shared/components/atoms/section-title/section-title"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import useMobile from "../../../shared/hooks/useMobile"

const SectionOurBrandsSlider = ({ className = '', contentInfo }) => {
  const [activeBrand, setActiveBrand] = React.useState(0)
  const isMobile = useMobile()

  const datalayerBrands = (nameBrand) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "ga_event",
      category: "Nuestras Marcas",
      action: "Click :: Conoce Más",
      label: nameBrand,
      value: "",
    })
  }

  const datalayerGoBrand = (nameBrand) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "ga_event",
      category: "Nuestras Marcas",
      action: "Click :: Conoce Más",
      label: "Ir a :: "+ nameBrand,
      value: "",
    })
  }

  return (
    <section className={"f-section-our-brands-slider " + className}>
      <div className="f-our-brands-header">
        {contentInfo?.informacionNuestrasMarcas && documentToReactComponents(
          JSON.parse(contentInfo.informacionNuestrasMarcas.raw),
          {
            renderNode: {
              [BLOCKS.HEADING_2]: (_, children) => (
                <SectionTitle barCenter={!isMobile}>
                  {children}
                </SectionTitle>
              ),
              [BLOCKS.PARAGRAPH]: (_, children) => (
                <p className="f-our-brands-header__description">{children}</p>
              )
            }
          }
        )}
        <div className="f-our-brands-wrapper">
          {contentInfo.nuestrasMarcas?.map(({ id, imagenDeLaCategoria, titulo }, index) => (
            <div
              key={id}
              className={`f-card-our-brand ${activeBrand === index ? "active" : ""}`}
              onClick={() => { 
                setActiveBrand(index)
                datalayerBrands(titulo)
              }}

            >
              <div className="f-icon-brand-container-img">
                <Img
                  loading="lazy"
                  itemProp="image"
                  className="img-brand f-card-our-brand__img"
                  fluid={imagenDeLaCategoria[0].fluid}
                  alt={imagenDeLaCategoria[0].description}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="f-our-brand-info-container">
        {
          contentInfo.nuestrasMarcas?.map(({ id, segundaImagenDeLaCategoria , redireccionDeLaCategoria, titulo, segundaDescripcionDeLaCategoria, descripcionDeLaCategoria }, index) => (
            <div
              key={`brand-${id}`}
              className={`f-our-brand-info ${index === activeBrand ? "show" : ""}`}
            >
              {segundaImagenDeLaCategoria &&
                <img
                  src={segundaImagenDeLaCategoria[0].file.url}
                  className="f-our-brand-info__img"
                />
              }
              <div className="f-our-brand-info__wrapper ">
                <div>
                  {segundaDescripcionDeLaCategoria && documentToReactComponents(
                    JSON.parse(segundaDescripcionDeLaCategoria.raw),
                    {
                      renderNode: {
                        [BLOCKS.HEADING_3]: (_, children) => (
                          <h3 className="f-our-brand-info__subtitle">
                            {children}
                          </h3>

                        ),
                        [BLOCKS.PARAGRAPH]: (_, children) => (
                          <p className="f-our-brand-info__description">{children}</p>
                        )
                      },
                      renderMark: {
                        [MARKS.BOLD]: text => (
                          <h3 className="f-our-brand-info__title">
                            {text}
                          </h3>
                        )
                      }
                    }
                  )}
                  {descripcionDeLaCategoria && documentToReactComponents(
                    JSON.parse(descripcionDeLaCategoria.raw),
                    {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (_, children) => (
                          <a className="f-our-brand-info__cta" href={redireccionDeLaCategoria} onClick={()=>{datalayerGoBrand(titulo)}}>
                            {children}
                            <i className="fal fa-arrow-right"></i>
                          </a>
                        )
                      }

                    }
                  )}
                </div>

              </div>
            </div>
          ))
        }
      </div>
    </section>
  )
}

export default SectionOurBrandsSlider
