import React, { useState } from "react"
import SliderImages from "../../../shared/components/molecules/slider-images/slider-images"
import RitchText from "../../../shared/components/molecules/rich-text/rich-text"
import SectionTitle from "../../../shared/components/atoms/section-title/section-title"
import Button from "../../../shared/components/atoms/button/button"
import "./section-brands-hitos.scss"

const SectionBrandsHitos = props => {
  const { contentInfo, sectionTitle } = props
  console.log("🚀 ~ file: section-brands-hitos.jsx ~ line 10 ~ contentInfo", contentInfo)

  const [currentSlide, setCurrentSlide] = useState(0)

  const imagesSlide = []

  contentInfo?.forEach((data, index) => {
    console.log("🚀 ~ file: section-brands-hitos.jsx ~ line 17 ~ contentInfo?.forEach ~ data", data)
    imagesSlide.push(data)
  })

  return (
    <section className="f-section-brands-hitos">
      <SectionTitle barCenter>
        {sectionTitle}
      </SectionTitle>
      <span className="f-curve-effect">
        <span className="f-curve-child-top"></span>
      </span>
      <div className="f-section-brands-hitos__container">
        <div className="f-section-brands-hitos__wrapper">
          <div className="f-section-brands-hitos__img">
            <SliderImages images={imagesSlide} setCurrentImage={setCurrentSlide} dots={true} titleSlider="Novedades"/>
          </div>
          <div className="f-section-brands-hitos__txt">
            {contentInfo[currentSlide]?.nombreDeLaCategoria &&
              <h3 className="f-section-brands-hitos__txt__title">{contentInfo[currentSlide]?.nombreDeLaCategoria}</h3>
            }
            {contentInfo[currentSlide]?.descripcionDeLaCategoria && (
              <RitchText jsonInfo={JSON.parse(contentInfo[currentSlide].descripcionDeLaCategoria.raw)} />
            )}
            {contentInfo[currentSlide]?.ctaDeLaCategoria && (
              <Button
                size="small"
                title={contentInfo[currentSlide]?.ctaDeLaCategoria}
                onClick={() => {
                  window.open(contentInfo[currentSlide]?.redireccionDeLaCategoria, "_blank")

                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionBrandsHitos
