import React from "react"
import "./slider-images.scss"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Img from "gatsby-image"

import ArrowsSlider from "../../atoms/arrows-slider/arrows-slider"

const SliderImages = props => {

  const { images, setCurrentImage, dummy = false, dots = false, titleSlider } = props
  const slider = React.useRef(null)

  const optionsSlide = {
    // autoplay: true,
    infinite: true,
    // speed: 2000,
    autoplaySpeed: 3000,
    dots: dots,
    centerMode: false,
    nextArrow: <ArrowsSlider direction="right" banner />,
    prevArrow: <ArrowsSlider direction="left" banner />,
    beforeChange: (current, next) => {
       let directionArrow = current < next ? "Siguiente" : "Anterior"
       if (current < images.length && next === 0 && current !==1) {
         directionArrow = "Siguiente"
       } else if (current === 0 && next < images.length && next !==1) {
         directionArrow = "Anterior"
       }
       if (titleSlider){
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "ga_event",
          category: "Transformación digital",
          action: "Click :: " + titleSlider,
          label: directionArrow,
          value: "",
        })
       }

      if (setCurrentImage) {
        setCurrentImage(next)
      }

      //reset video current video
      let currentVideo = document.getElementById("video" + current)
      let listElements = document.getElementsByTagName("iframe")

      for (let i = 0; i < listElements.length; i++) {
        const video = listElements[i];

        if (video && video?.getAttribute("src") === currentVideo?.getAttribute("src")) {
          video?.setAttribute("src", video?.getAttribute("src"))
        }
      }
    },
    slidesToShow: 1,
    slidesToScroll: 1
  }


  return (
    <div className="f-slider-images">

      <Slider {...optionsSlide} ref={slider}>

        {images?.map((item, index) => {
          if (dummy) {
            return (
              <div key={index} className="slide-image"><img src={item} alt="Imagen Dumy slide" /></div>
            )
          } else if (item.laImagenEsUnVideo && item.laImagenEsUnVideo === true) {
            return <iframe
              key={index}
              id={"video" + index}
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="no"
              src={
                item.urlDelVideo.includes('youtube') ?
                  item.urlDelVideo.replace("watch?v=", "embed/") :
                  item.urlDelVideo.includes('youtu.be') ?
                    item.urlDelVideo.replace(".be/", "be.com/embed/") :
                    item.urlDelVideo
              }
              allowFullScreen
              sandbox="allow-popups allow-same-origin allow-scripts allow-presentation allow-modals"
              allow="microphone; camera; fullscreen;"
              className="f-container-video-iframe"
              title="video"
            />

          } else {
            return (
              <div key={index} className="slide-image">
                <Img
                  loading="lazy"
                  itemProp="image"
                  className="img-fluid"
                  fluid={item.fluid}
                  alt={(item.description) ? item.description : 'Imagen Slider'}
                />
              </div>
            )

          }

        })}
      </Slider>
    </div>

  )

}

export default SliderImages
