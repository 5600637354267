import React from "react"
import Layout from "../shared/components/organisms/layout/layout"
import SEO from "../shared/components/atoms/seo/seo"
import { graphql } from "gatsby"
import OurBrandContent from '../components/organisms/content-out-brands/content-out-brands'
import "../shared/styles/index.scss"

const IndexPage = (props) => {
    const { allContentfulExtranetSeccionNuestrasMarcas } = props.data
    const ourBrandsInfo = allContentfulExtranetSeccionNuestrasMarcas.nodes[0]

    return (
        <Layout>
            <SEO
                title={ourBrandsInfo.metaTitulo}
                description={ourBrandsInfo.metaDescripcion.metaDescripcion}
                image={"https:" + ourBrandsInfo.bannerPrincipal.imagenesDelBanner[0].file.url}
            />
            <OurBrandContent ourBrandsInfo={ourBrandsInfo} />
        </Layout>
    )
}

export const query = graphql`
    query OUR_BRANDS_INFO {
        allContentfulExtranetSeccionNuestrasMarcas(filter: {slug: {eq: "nuestras-marcas/"}}) {
        nodes {
            slug
            titulo
            node_locale
            metaTitulo
            metaDescripcion {
            metaDescripcion
            }
            bannerPrincipal {
            imagenesDelBanner {
                description
                file {
                url
                details {
                    image {
                    height
                    }
                }
                }
                fluid {
                src
                srcSet
                base64
                aspectRatio
                sizes
                }
            }
            }
            tituloPrincipal
            descripcionPrincipal {
            raw
            }
            imagenPrincipal {
            description
            file {
                url
                details {
                image {
                    height
                }
                }
            }
            fluid {
                src
                srcSet
                base64
                aspectRatio
                sizes
            }
            }
            informacionNuestrasMarcas {
				raw
            }
            nuestrasMarcas {
            id
            titulo
            imagenDeLaCategoria {
                file {
                    url
                details {
                    image {
                        height
                    }
                }
                }
                fluid {
                    src
                srcSet
                base64
                aspectRatio
                sizes
                }
            }
            segundaImagenDeLaCategoria {
                file {
                    url
                details {
                    image {
                        height
                    }
                }
                }
                fluid {
                    src
                srcSet
                base64
                aspectRatio
                sizes
                }
            }
            segundaDescripcionDeLaCategoria {
                raw
            }
            descripcionDeLaCategoria {
                raw
            }
            redireccionDeLaCategoria
            }
            tituloHitosDestacados
            hitosDestacados {
				nombreDeLaCategoria
                descripcionDeLaCategoria {
                    raw
                }
                imagenDeLaCategoria {
                    description
                    title
                    file {
                        url
                        details {
                            image {
                            height
                        }
                        }
                    }
                    fluid {
                        src
                        srcSet
                        base64
                        aspectRatio
                        sizes
                    }
                    }
                    ctaDeLaCategoria
                    redireccionDeLaCategoria
                    laImagenEsUnVideo
                    urlDelVideo
                }
            }
        }
    }  
`


export default IndexPage
